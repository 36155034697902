import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderComponent } from './header-component';
import { HeroComponent } from './hero-component';
import { SocialProofComponent } from './social-proof-component';
import { FeaturesComponent } from './features-component';
import { TestimonialsComponent } from './testimonials-component';
import { FaqComponent } from './faq-component';
import { CtaComponent } from './cta-component';
import { FounderNoteComponent } from './founder-note-component';
import { FooterComponent } from './footer-component';
import { DemoComponent } from './demo.component';
import { PricingComponent, PricingPlan } from './pricing.component';
import { TranslocoService } from '@jsverse/transloco';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    HeroComponent,
    SocialProofComponent,
    FeaturesComponent,
    TestimonialsComponent,
    FaqComponent,
    CtaComponent,
    FounderNoteComponent,
    FooterComponent,
    DemoComponent,
    PricingComponent
  ],
  template: `
    <app-hero></app-hero>
    <!-- <app-social-proof></app-social-proof> -->
    <app-features></app-features>
    <app-demo></app-demo>
    <app-pricing [plans]="pricingPlans"></app-pricing>
    <!-- <app-testimonials></app-testimonials> -->
    <app-faq></app-faq>
    <app-cta></app-cta>
    <!-- <app-founder-note></app-founder-note> -->
    <app-footer></app-footer>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {
  translateService = inject(TranslocoService);

  // TODO: Replace with call from backend
  pricingPlans: PricingPlan[] = environment.pricingPlans.map((pricingPlan) => ({
    ...pricingPlan,
    name: pricingPlan.name ? this.translateService.translate(pricingPlan.name) : 'Plan',
    yearlyBenefit: pricingPlan.yearlyBenefit ? this.translateService.translate(pricingPlan.yearlyBenefit) : undefined,
    monthlyBenefit: pricingPlan.monthlyBenefit ? this.translateService.translate(pricingPlan.monthlyBenefit) : undefined,
    features: pricingPlan.features.map((feature) => this.translateService.translate(feature)),
  }));
}
