import { AsyncPipe, CurrencyPipe, JsonPipe, NgClass, NgFor, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, ElementRef, HostListener, inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, EMPTY, map, Observable, switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CartaService, Product, PublicMenuWithProducts } from '../../services/carta.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-carta',
  standalone: true,
  imports: [NgIf, NgFor, AsyncPipe, CurrencyPipe, NgClass, NgOptimizedImage, NgStyle, TranslocoModule, JsonPipe],
  template: `
  <div class="container mx-auto px-4 py-4 md:py-8 pt-0" *transloco="let t; read: 'dashboard.menus.products'">
  @if (menuWithProducts$ | async; as menuData) {
    <div class="relative mb-8 bg-gray-200 rounded-lg shadow-lg">
      @if (menuData.images && menuData.images.length > 0) {
        <img [ngSrc]="menuData.images[0].url"
             alt="Menu header"
             width="1200"
             height="400"
             class="w-full h-64 object-cover rounded-lg"
             priority>
        <div class="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
      } @else {
        <div class="w-full h-64 bg-gray-300 rounded-lg"></div>
      }
      <h1 class="text-4xl font-bold mb-6 absolute bottom-4 left-4 z-10"
          [ngClass]="{'text-white': menuData.images && menuData.images.length > 0, 'text-gray-800': !menuData.images || menuData.images.length === 0}">
        {{ menuData.displayName }}
      </h1>
    </div>
    @if (menuData.products.length) {
      <div [ngClass]="{'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8': menuData.view_type === 'grid'}">
          @for (product of menuData.products; track product.id) {
            <div [ngClass]="{'bg-white shadow rounded-lg overflow-hidden flex': true, 'flex-col': menuData.view_type === 'grid', 'mb-4': menuData.view_type === 'list', 'col-span-full': menuData.view_type === 'grid' && expandedProducts[product.id]}"
                   [class.expanded]="isMobile && expandedProducts[product.id]"
                   [attr.data-product-id]="product.id"
                   >
            @if (product.images && product.images.length > 0) {
              <div class="relative w-full h-48"
                   [ngClass]="{'hidden': menuData.view_type === 'list'}"
                   (touchstart)="onTouchStart($event)"
                   (touchend)="onTouchEnd($event, product)"
                   (click)="onImageClick($event, product)">
                @for (image of product.images; track image; let i = $index) {
                  <img [ngSrc]="image.url"
                       [alt]="product.display_name"
                       width="300"
                       height="300"
                       class="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ease-in-out"
                       [ngClass]="{'opacity-100': i === (currentImageIndex$[product.id] | async), 'opacity-0': i !== (currentImageIndex$[product.id] | async)}">
                }
                @if (product.images.length > 1) {
                  <div class="absolute bottom-2 left-0 right-0 flex justify-center">
                    @for (image of product.images; track image; let i = $index) {
                      <button class="w-2 h-2 rounded-full mx-1"
                              [ngClass]="{'bg-white': i === (currentImageIndex$[product.id] | async), 'bg-gray-300': i !== (currentImageIndex$[product.id] | async)}"
                              (click)="changeImage(product, i)"></button>
                    }
                  </div>
                }
              </div>
            } @else {
              <div class="w-full h-48 bg-gray-200" [ngClass]="{'hidden': menuData.view_type === 'list'}"></div>
            }
              <div class="p-4 relative flex-1 flex flex-col justify-between">
                @if (menuData.view_type === 'grid') {
                  <div (click)="toggleExpand(product.id, $event)">
                    <h2 class="text-xl font-semibold mb-2">{{ product.display_name }}</h2>
                    <p class="text-gray-600 mb-2 description" [class.line-clamp-3]="!expandedProducts[product.id]">{{ product.description || '' }}</p>
                    <p class="text-xl font-bold flex justify-between">
                      {{ product.price | currency:menuData.currency:'symbol-narrow' }}
                      @if (isMobile) {
                        @if (expandedProducts[product.id]) {
                          <svg class="expand-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.24000000000000005"></g><g id="SVGRepo_iconCarrier"> <g id="Arrow / Shrink"> <path id="Vector" d="M5 14H10V19M19 10H14V5" stroke="#7a7a7a" stroke-width="0.43200000000000005" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                        } @else {
                          <svg class="shrink-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Arrow / Expand"> <path id="Vector" d="M10 19H5V14M14 5H19V10" stroke="#7a7a7a" stroke-width="0.43200000000000005" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                        }
                      }
                    </p>
                  </div>
                } @else {
                  <div class="flex justify-between items-start">
                    <h2 class="text-xl font-semibold">{{ product.display_name }}</h2>
                    <p class="text-xl font-bold">{{ product.price | currency:menuData.currency:'symbol-narrow' }}</p>
                  </div>
                  @if (product.description) {
                    <div class="relative overflow-hidden"
                        [class.h-6]="!expandedProducts[product.id]"
                        [class.h-auto]="expandedProducts[product.id]">
                      <p class="text-gray-600 mt-1 cursor-pointer transition-all duration-300 ease-in-out"
                        (click)="toggleExpand(product.id, $event)"
                        [class.hidden]="expandedProducts[product.id]"
                        [class.opacity-100]="!expandedProducts[product.id]"
                        [class.opacity-0]="expandedProducts[product.id]"
                        >
                        {{ product.description.split(' ').slice(0, 5).join(' ') }}...
                      </p>
                      <p class="relative text-gray-600 mt-1 cursor-pointer transition-all duration-300 ease-in-out absolute top-0 left-0 right-0"
                        (click)="toggleExpand(product.id, $event)"
                        [class.opacity-0]="!expandedProducts[product.id]"
                        [class.opacity-100]="expandedProducts[product.id]"
                        >
                        {{ product.description }}
                      </p>
                    </div>
                  }
                }
              </div>
            </div>
          }
        </div>
    } @else {
      <p class="text-center text-gray-500" [ngStyle]="{'font-size': isHighPixelRatio ? 'calc(1rem * var(--high-dpr-scale))' : '1rem'}">{{ t('noProductsInMenu') }}</p>
    }
  } @else {
    <div class="flex justify-center items-center">
      <div class="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
    </div>
  }
</div>

  `,
  styles: [`
    @media (max-width: 767px) {
      .product-card {
        transition: all 0.3s ease-in-out;
      }
      .product-card.expanded {
        grid-column: 1 / -1;
      }
      .description {
        max-height: 4.5em;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
      }
      .expanded .description {
        max-height: 1000px; /* Arbitrary large value */
      }

      .expand-icon, .shrink-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
    /* List view styles */
    :host-context([data-view-type="list"]) .product-card {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
    }

    :host-context([data-view-type="list"]) .product-card img {
      width: 150px;
      height: 150px;
    }

    /* Grid view styles */
    :host-context([data-view-type="grid"]) .product-card {
      display: flex;
      flex-direction: column;
    }

    :host-context([data-view-type="grid"]) .product-card img {
      width: 100%;
      height: 200px;
    }
  `]
})
export class CartaComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private cartaService = inject(CartaService);
  private ngZone = inject(NgZone);
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);

  public apiUrl = environment.apiUrl;
  public currentImageIndex$: { [key: number]: BehaviorSubject<number> } = {};
  private touchStartX: number | null = null;
  public expandedProducts: { [key: number]: boolean } = {};
  public isMobile: boolean = false;

  menuWithProducts$!: Observable<PublicMenuWithProducts>;
  isHighPixelRatio: boolean = false;

  ngOnInit() {
    this.checkIfMobile();
    this.menuWithProducts$ = this.route.paramMap.pipe(
      switchMap(params => {
        const publicName = params.get('publicName');
        if (publicName) {
          return this.cartaService.getPublicProductsInMenu(publicName).pipe(
            map(menuWithProducts => {
              menuWithProducts.products.forEach(product => {
                this.currentImageIndex$[product.id] = new BehaviorSubject(0);
              });
              this.renderer.setAttribute(this.el.nativeElement, 'data-view-type', menuWithProducts.view_type);
              return menuWithProducts;
            }),
            catchError(error => {
              console.error('Error fetching menu:', error);
              return EMPTY;
            })
          );
        }
        throw new Error('Public name not provided');
      })
    );
    this.isHighPixelRatio = window.devicePixelRatio > 2;

    // Set CSS custom property for font size adjustment
    if (this.isHighPixelRatio) {
      console.log('High pixel ratio detected');
      console.log(window.devicePixelRatio);
      document.documentElement.style.setProperty('--high-dpr-scale', '1.2');
    } else {
      document.documentElement.style.setProperty('--high-dpr-scale', '1');
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkIfMobile();
  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 767;
  }

  toggleExpand(productId: number, event: MouseEvent) {
    event.stopPropagation();
    const isExpanding = !this.expandedProducts[productId];
    this.expandedProducts[productId] = isExpanding;

    const target = event.currentTarget as HTMLElement;
    const isGridView = target.closest('[data-view-type="grid"]') !== null;

    if (isGridView) {
      if (isExpanding) {
        target.classList.add('col-span-full', 'expanded');
        setTimeout(() => this.scrollToCenter(target), 50);
      } else {
        target.classList.remove('col-span-full', 'expanded');
      }
    }
  }

  private scrollToCenter(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetTop = rect.top + scrollTop - (window.innerHeight / 2) + (rect.height / 2);

    this.smoothScroll(targetTop, 800); // Increased duration to 800ms for smoother scrolling
  }

  private smoothScroll(targetPosition: number, duration: number) {
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = this.easeInOutCubic(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(animation);
    });
  }

  private easeInOutCubic(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t * t + b;
    t -= 2;
    return c / 2 * (t * t * t + 2) + b;
  }

  changeImage(product: Product, index: number) {
    if (product.images) {
      const newIndex = (index + product.images.length) % product.images.length;
      this.currentImageIndex$[product.id].next(newIndex);
    }
  }

  navigateImage(product: Product, direction: 1 | -1) {
    if (product.images) {
      const currentIndex = this.currentImageIndex$[product.id].getValue();
      const newIndex = (currentIndex + direction + product.images.length) % product.images.length;
      this.changeImage(product, newIndex);
    }
  }

  onTouchStart(event: TouchEvent) {
    this.touchStartX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent, product: Product) {
    if (this.touchStartX !== null) {
      const touchEndX = event.changedTouches[0].clientX;
      const diff = touchEndX - this.touchStartX;
      if (Math.abs(diff) > 50) { // Threshold for swipe
        this.navigateImage(product, diff > 0 ? -1 : 1);
      }
      this.touchStartX = null;
    }
  }

  onImageClick(event: MouseEvent, product: Product) {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const x = event.clientX - rect.left;
    const width = rect.width;
    if (x < width / 2) {
      this.navigateImage(product, -1);
    } else {
      this.navigateImage(product, 1);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const activeElement = document.activeElement;
      if (activeElement && activeElement.closest('.product-card')) {
        const productId = parseInt(activeElement.closest('.product-card')!.getAttribute('data-product-id')!, 10);
        this.menuWithProducts$.pipe(
          map(menuData => menuData.products.find(p => p.id === productId)),
          take(1)
        ).subscribe(product => {
          if (product) {
            this.navigateImage(product, event.key === 'ArrowLeft' ? -1 : 1);
          }
        });
      }
    }
  }
}
