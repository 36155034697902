import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';

export interface PricingPlan {
  name: string;
  features: string[];
  yearlyPrice: string;
  yearlyBenefit?: string;
  monthlyPrice: string;
  monthlyBenefit?: string;
  yearlyCheckoutUrl: string;
  monthlyCheckoutUrl: string;
}

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslocoModule],
  template: `
    <section
        class="py-16 bg-gray-100"
        id="pricing"
        *transloco="let t; read: 'pricing'"
    >
      <div class="container mx-auto px-4">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold mb-8">{{ t('title') }}</h2> <!-- Pricing Plans -->

          <!-- Pricing Toggle -->
          <div class="flex items-center justify-center gap-4 mb-8">
            <span [class.text-gray-900]="!isYearly" [class.text-gray-500]="isYearly">{{ t('monthly') }}</span><!-- Monthly -->
            <label class="relative inline-flex items-center cursor-pointer">
              <input type="checkbox"
                     [(ngModel)]="isYearly"
                     class="sr-only peer">
              <div class="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer
                          peer-checked:after:translate-x-full peer-checked:after:border-white
                          after:content-[''] after:absolute after:top-0.5 after:left-[4px]
                          after:bg-white after:border-gray-300 after:border after:rounded-full
                          after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600">
              </div>
            </label>
            <span [class.text-gray-900]="isYearly" [class.text-gray-500]="!isYearly">{{ t('yearly') }}</span><!-- Yearly -->
          </div>
        </div>

        <!-- Pricing Cards -->
        <div class="flex flex-wrap justify-around gap-8">
          <div *ngFor="let plan of plans"
               class="flex flex-col w-full md:w-80 p-6 bg-white rounded-2xl shadow-lg
                      transition-transform duration-300 hover:scale-105">

            <!-- Plan Header -->
            <div class="text-center mb-6">
              <h3 class="text-2xl font-bold mb-4">{{ plan.name }}</h3>
              <div class="text-4xl font-bold mb-2">
                {{ isYearly ? plan.yearlyPrice : plan.monthlyPrice }}
              </div>

              <!-- Benefits Badge -->
              <div *ngIf="(isYearly && plan.yearlyBenefit) || (!isYearly && plan.monthlyBenefit)"
                   class="inline-block px-3 py-1 bg-green-100 text-green-800 text-sm rounded-full">
                {{ isYearly ? plan.yearlyBenefit : plan.monthlyBenefit }}
              </div>
            </div>

            <!-- Features List -->
            <ul *ngIf="plan.features.length > 0" class="flex-grow space-y-4 mb-6">
              <li *ngFor="let feature of plan.features"
                  class="flex items-center">
                <svg class="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7">
                  </path>
                </svg>
                {{ feature }}
              </li>
            </ul>

            <!-- CTA Button -->
            <a [href]="isYearly ? plan.yearlyCheckoutUrl : plan.monthlyCheckoutUrl"
               target="_blank"
               class="w-full py-3 px-6 text-center text-white bg-blue-600 rounded-lg
                      hover:bg-blue-700 active:bg-blue-800
                      transition-colors duration-200
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              {{t('getStarted')}}
            </a>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingComponent {
  @Input() plans: PricingPlan[] = [];
  isYearly = false;
}