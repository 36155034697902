import { Component, HostListener, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { AsyncPipe, NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { environment } from '../../../environments/environment';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-layout',
  template: `

    <div class="min-h-screen bg-gray-100 flex flex-col" *transloco="let t; read: 'layout'">
      <!-- Desktop Navbar -->
      <nav class="bg-white shadow-sm hidden md:block">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <a href="/">
                  <img src="/assets/transparentHeaderDarkSmall.png" alt="Carta Ya Logo" class="h-8">
                </a>
              </div>
            </div>
            <div class="flex items-center">
              <ng-container *ngTemplateOutlet="menuItems"></ng-container>
            </div>
          </div>
        </div>
      </nav>

      <!-- Mobile Top Bar -->
      <div class="md:hidden bg-white shadow-sm fixed top-0 left-0 right-0 z-10">
        <div class="flex items-center justify-between h-16 px-4">
          <!-- Hamburger menu button -->
          <button (click)="toggleMobileMenu($event)" class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 hamburguerButton">
            <span class="sr-only">{{ 'layout.open' | transloco }}</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>

          <!-- App Name -->
          <a href="/" class="text-xl font-bold text-indigo-600"><img src="/assets/transparentHeaderDarkSmall.png" alt="Carta Ya Logo" class="h-6"></a>

          <!-- Empty div to balance the flex layout -->
          <div class="w-10"></div>
        </div>
      </div>

      <!-- Mobile Sidemenu -->
      <div class="md:hidden">
        <!-- Sidemenu -->
        <div [ngClass]="{'translate-x-0': isMobileMenuOpen, '-translate-x-full': !isMobileMenuOpen}"
            class="fixed inset-y-0 left-0 z-30 w-64 bg-white overflow-y-auto transition duration-300 ease-in-out transform shadow-lg sideMenu">
          <div class="flex items-center justify-between p-4 border-b">
            <a href="/" class="text-xl font-bold text-indigo-600"><img src="/assets/transparentHeaderDarkSmall.png" alt="Carta Ya Logo" class="h-6"></a>
            <button (click)="toggleMobileMenu($event)" class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span class="sr-only">{{ t('close') }}</span>
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="p-4">
            <ng-container *ngTemplateOutlet="mobileMenuItems"></ng-container>
          </div>
        </div>
      </div>

      <!-- Main Content -->
      <main class="flex-grow md:mt-0 mt-16">
        <div [ngClass]="{'max-w-7xl mx-auto py-6 sm:px-6 lg:px-8': router.url !== '/', 'max-w-7xl mx-auto': router.url === '/'}">
          <router-outlet></router-outlet>
        </div>
      </main>
    </div>


<!-- Desktop Menu Items Template -->
<ng-template #menuItems>
  <ng-container *ngIf="isLoggedIn$ | async; else loginButton">
  <a href="https://app.supademo.com/demo/cm0pn5hed002wckoslq8rghdb" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" target="_blank">
  <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="#0F0F0F"/>
<path d="M13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18C10.5 17.1716 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1716 13.5 18Z" fill="#0F0F0F"/>
<path d="M11 12V14C11 14 11 15 12 15C13 15 13 14 13 14V12C13 12 13.4792 11.8629 13.6629 11.7883C13.6629 11.7883 13.9969 11.6691 14.2307 11.4896C14.4646 11.3102 14.6761 11.097 14.8654 10.8503C15.0658 10.6035 15.2217 10.3175 15.333 9.99221C15.4443 9.66693 15.5 9.4038 15.5 9C15.5 8.32701 15.3497 7.63675 15.0491 7.132C14.7596 6.61604 14.3476 6.21786 13.8132 5.93745C13.2788 5.64582 12.6553 5.5 11.9427 5.5C11.4974 5.5 11.1021 5.55608 10.757 5.66825C10.4118 5.7692 10.1057 5.9094 9.83844 6.08887C9.58236 6.25712 9.36525 6.4478 9.18711 6.66091C9.02011 6.86281 8.8865 7.0591 8.78629 7.24978C8.68609 7.44046 8.61929 7.6087 8.58589 7.75452C8.51908 7.96763 8.49125 8.14149 8.50238 8.27609C8.52465 8.41069 8.59145 8.52285 8.70279 8.61258C8.81413 8.70231 8.9867 8.79765 9.22051 8.8986C9.46546 8.97712 9.65473 9.00516 9.78834 8.98273C9.93308 8.96029 10.05 8.89299 10.1391 8.78083C10.1391 8.78083 10.6138 8.10569 10.7474 7.97109C10.8922 7.82528 11.0703 7.71312 11.2819 7.6346C11.4934 7.54487 11.7328 7.5 12 7.5C12.579 7.5 13.0076 7.64021 13.286 7.92062C13.5754 8.18982 13.6629 8.41629 13.6629 8.93225C13.6629 9.27996 13.6017 9.56038 13.4792 9.77349C13.3567 9.9866 13.1953 10.1605 12.9949 10.2951C12.9949 10.2951 12.7227 10.3991 12.5 10.5C12.2885 10.5897 11.9001 10.7381 11.6997 10.8503C11.5104 10.9512 11.4043 11.0573 11.2819 11.2144C11.1594 11.3714 11 11.7308 11 12Z" fill="#0F0F0F"/>
</svg></a>
    <a routerLink="/dashboard" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ 'layout.dashboard' | transloco }}</a>
    <a routerLink="/settings" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ 'layout.settings' | transloco }}</a>
    <a data-canny-link target="_blank" href="https://carta-ya.canny.io" (click)="toggleMobileMenu()" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ 'layout.canny' | transloco }}</a>
    <button (click)="logout()" class="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ 'layout.logout' | transloco }}
    </button>
  </ng-container>
  <ng-template #loginButton>
    <a href="#pricing" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ 'layout.pricing' | transloco }}</a>
    <a routerLink="/login" class="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">{{ 'layout.login' | transloco }}</a>
    <a routerLink="/register" class="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ 'layout.register' | transloco }}
    </a>
  </ng-template>
</ng-template>

<!-- Mobile Menu Items Template -->
<ng-template #mobileMenuItems>
  <ng-container *ngIf="isLoggedIn$ | async; else mobileLoginButton">
    <a routerLink="/dashboard" (click)="toggleMobileMenu()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ 'layout.dashboard' | transloco }}</a>
    <a routerLink="/settings" (click)="toggleMobileMenu()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ 'layout.settings' | transloco }}</a>
    <a data-canny-link target="_blank" href="https://carta-ya.canny.io" (click)="toggleMobileMenu()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ 'layout.canny' | transloco }}</a>
    <a href="https://app.supademo.com/demo/cm0pn5hed002wckoslq8rghdb" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" target="_blank"><svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="#0F0F0F"/>
<path d="M13.5 18C13.5 18.8284 12.8284 19.5 12 19.5C11.1716 19.5 10.5 18.8284 10.5 18C10.5 17.1716 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1716 13.5 18Z" fill="#0F0F0F"/>
<path d="M11 12V14C11 14 11 15 12 15C13 15 13 14 13 14V12C13 12 13.4792 11.8629 13.6629 11.7883C13.6629 11.7883 13.9969 11.6691 14.2307 11.4896C14.4646 11.3102 14.6761 11.097 14.8654 10.8503C15.0658 10.6035 15.2217 10.3175 15.333 9.99221C15.4443 9.66693 15.5 9.4038 15.5 9C15.5 8.32701 15.3497 7.63675 15.0491 7.132C14.7596 6.61604 14.3476 6.21786 13.8132 5.93745C13.2788 5.64582 12.6553 5.5 11.9427 5.5C11.4974 5.5 11.1021 5.55608 10.757 5.66825C10.4118 5.7692 10.1057 5.9094 9.83844 6.08887C9.58236 6.25712 9.36525 6.4478 9.18711 6.66091C9.02011 6.86281 8.8865 7.0591 8.78629 7.24978C8.68609 7.44046 8.61929 7.6087 8.58589 7.75452C8.51908 7.96763 8.49125 8.14149 8.50238 8.27609C8.52465 8.41069 8.59145 8.52285 8.70279 8.61258C8.81413 8.70231 8.9867 8.79765 9.22051 8.8986C9.46546 8.97712 9.65473 9.00516 9.78834 8.98273C9.93308 8.96029 10.05 8.89299 10.1391 8.78083C10.1391 8.78083 10.6138 8.10569 10.7474 7.97109C10.8922 7.82528 11.0703 7.71312 11.2819 7.6346C11.4934 7.54487 11.7328 7.5 12 7.5C12.579 7.5 13.0076 7.64021 13.286 7.92062C13.5754 8.18982 13.6629 8.41629 13.6629 8.93225C13.6629 9.27996 13.6017 9.56038 13.4792 9.77349C13.3567 9.9866 13.1953 10.1605 12.9949 10.2951C12.9949 10.2951 12.7227 10.3991 12.5 10.5C12.2885 10.5897 11.9001 10.7381 11.6997 10.8503C11.5104 10.9512 11.4043 11.0573 11.2819 11.2144C11.1594 11.3714 11 11.7308 11 12Z" fill="#0F0F0F"/>
</svg></a>
    <button (click)="logout()" class="mt-4 w-full px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ 'layout.logout' | transloco }}
    </button>
  </ng-container>
  <ng-template #mobileLoginButton>
    <a routerLink="/login" (click)="toggleMobileMenu()" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ 'layout.login' | transloco }}</a>
    <a routerLink="/register" (click)="toggleMobileMenu()" class="mt-4 block w-full px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      {{ 'layout.register' | transloco }}
    </a>
  </ng-template>
</ng-template>
  `,
  standalone: true,
  imports: [RouterModule, NgIf, AsyncPipe, NgClass, NgTemplateOutlet, TranslocoModule]
})
export class LayoutComponent {
  isLoggedIn$: Observable<boolean>;
  router = inject(Router);
  isMobileMenuOpen = false;
  appName = environment.appName;
  translateService = inject(TranslocoService);

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const mobileMenu = document.querySelector('.sideMenu');
    const hamburgerButton = document.querySelector('button.hamburguerButton');
    if (this.isMobileMenuOpen && mobileMenu && hamburgerButton) {
      if (!mobileMenu.contains(event.target as Node) && !hamburgerButton.contains(event.target as Node)) {
        this.isMobileMenuOpen = false;
      }
    }
  }

  constructor(private authService: AuthService) {
    this.isLoggedIn$ = this.authService.isLoggedIn();
  }

  toggleMobileMenu(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (window.innerWidth >= 768 && this.isMobileMenuOpen) {
      this.isMobileMenuOpen = false;
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
    this.isMobileMenuOpen = false;
  }
}

